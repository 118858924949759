// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.text-font {
  font-family: 'Outfit', sans-serif !important;
}


.gilroy-font {
  font-family: "Gilroy-Medium" !important;
}

/* Define styles for lg screens (1280px and above) */
@media screen and (min-width: 1280px) {
  .text-fontbr::after {
    content: "";
    display: block;
    height: 1rem; /* Adjust the height as needed */
  }
}
img {
  user-drag: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AAEA;EACE,4CAA4C;AAC9C;;;AAGA;EACE,uCAAuC;AACzC;;AAEA,oDAAoD;AACpD;EACE;IACE,WAAW;IACX,cAAc;IACd,YAAY,EAAE,gCAAgC;EAChD;AACF;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,uBAAuB;AACzB","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap');\n.text-font {\n  font-family: 'Outfit', sans-serif !important;\n}\n\n\n.gilroy-font {\n  font-family: \"Gilroy-Medium\" !important;\n}\n\n/* Define styles for lg screens (1280px and above) */\n@media screen and (min-width: 1280px) {\n  .text-fontbr::after {\n    content: \"\";\n    display: block;\n    height: 1rem; /* Adjust the height as needed */\n  }\n}\nimg {\n  user-drag: none;\n  -moz-user-drag: none;\n  -webkit-user-drag: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
