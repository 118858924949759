import React, { useState } from "react";
import condot from "../../assets/home/dots.svg";
import {
  Grid,
  Container,
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import EmailIcon from "../../assets/contactus/sms.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DownIcon from "../../assets/contactus/arrow-down.svg";
import * as yup from "yup";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .nullable()
    .defined()
    .required("Name is required")
    .matches(
      /^(?!\s*$)[A-Za-z\s]*$/,
      "Numeric values, only spaces and special characters are not allowed"
    ),
  phoneNumber: yup.string().required("Phone / Mobile number is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  query: yup
    .string()
    .required("Query is required")
    .trim()
    .matches(
      /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
      "Special characters are not allowed"
    ),
});

const faqs = [
  {
    question: "What is a Membership Wallet?",
    answer:
      "A Membership Wallet is a digital tool that allows you to store and manage your membership cards, loyalty points, and exclusive offers all in one place. It simplifies access to your benefits and makes it easier to redeem rewards.",
  },
  {
    question: "How do I sign up for the Membership Wallet?",
    answer:
      'To sign up, simply click on the "Sign Up" button on our homepage, fill in the required information, and follow the instructions to complete the registration process.',
  },
  {
    question: "Are my details safe and secure?",
    answer:
      "Yes, we prioritize your security. Our Membership Wallet uses advanced encryption and security measures to protect your personal information and transaction history.",
  },
  {
    question: "Who do I contact for support?",
    answer:
      'If you need assistance, you can contact our customer support team via the "Contact Us" section on our website. Our team is available 24/7 to help with any issues or questions.',
  },
];

const ContactUs = () => {
  const [expanded, setExpanded] = useState(new Array(faqs.length).fill(false));
  const [open, setOpen] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      query: data.query,
    };
    console.log("payload", payload);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AAC_BASE_URL}/enquiries`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${process.env.REACT_APP_ENQUIRIES_API_KEY}`,
            // "42b35dead5919d6f578dbda5152fc51a:f7ff3fb5f9e41da29b73fbfb6d1b02013698a99e4309cc247e09fd9575b1b78956dd12d46e00da35dbc3b3fced1aef618ebc467f06e333d721147ee7ff8ed3fa2812a5fd58013484ad7e38049aefc47302da64259e596b258030fa6894464ef5",
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setOpen(true);
      reset();
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const connectdivStyle = {
    background: "var(--gray-scale-gray-50, #F9FAFB)",
    borderRadius: "0px 0px 300px 300px",
    padding: "20px",
    height: "643px",
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth="xxl">
          <Box
            sx={{
              display: { lg: "flex", xs: "none" },
              pr: "50px",
              position: "relative",
            }}
          >
            <img
              src={condot}
              alt="img"
              style={{
                marginLeft: "auto",
                position: "absolute",
                right: 70,
                top: 170,
              }}
            ></img>
          </Box>
        </Container>

        <div style={connectdivStyle}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sx={{ mt: "170px" }}>
                <Typography
                  variant="h6"
                  className="text-font"
                  sx={{
                    color: "#8155FF",
                    fontSize: "60px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "72px",
                    letterSpacing: "-1.2px",
                    textAlign: "center",
                  }}
                >
                  Contact Us
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container maxWidth="lg">
          <Grid container sx={{ position: "relative" }}>
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "20px",
                background: "#FFF",
                boxShadow: "0px 24px 94px -10px rgba(78, 78, 78, 0.20)",
                maxWidth: "1109px",
                width: "100%",
                marginTop: "-300px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ px: { md: "107px", sm: "40px", xs: "10px" }, mt: "50px" }}
              >
                <Grid item lg={12} xs={12} gap={2}>
                  <InputLabel
                    className="text-font"
                    sx={{
                      fontSize: "16px",
                      // py: "8px",
                      color: "3B4A45",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    Name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter name"
                    className="text-font"
                    sx={{
                      fontSize: "16px",
                      background: "#F9FAFB",
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#8155FF",
                        },
                      },
                    }}
                    {...register("name")}
                    error={Boolean(errors.name)}
                  />
                  <Box sx={styles.validationText}>
                    {errors.name && errors.name.message}
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ px: { md: "107px", sm: "40px", xs: "10px" }, mt: "10px" }}
              >
                <Grid item lg={6} xs={12} sx={{}}>
                  <InputLabel
                    className="text-font"
                    sx={{
                      fontSize: "16px",
                      // py: "8px",
                      color: "3B4A45",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    Email <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Enter email"
                    className="text-font"
                    sx={{
                      fontSize: "16px",
                      background: "#F9FAFB",
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#8155FF",
                        },
                      },
                    }}
                    {...register("email")}
                    error={Boolean(errors.email)}
                  />
                  <Box sx={styles.validationText}>
                    {errors.email && errors.email.message}
                  </Box>
                </Grid>

                <Grid item lg={6} xs={12}>
                  <Typography>
                    <InputLabel
                      className="text-font"
                      sx={{
                        fontSize: "16px",
                        // py: "8px",
                        color: "3B4A45",
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      className="text-font"
                      // type="number"
                      sx={{
                        fontSize: "16px",
                        background: "#F9FAFB",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#8155FF",
                          },
                        },
                      }}
                      placeholder="+44 1234567890"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "#4B5563" }}
                          >
                            UK{" "}
                            <img
                              src={DownIcon}
                              alt="My Icon"
                              style={{ height: "20px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      {...register("phoneNumber")}
                      error={Boolean(errors.phoneNumber)}
                    />
                    <Box sx={styles.validationText}>
                      {errors.phoneNumber && errors.phoneNumber.message}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ px: { md: "107px", sm: "40px", xs: "10px" }, mt: "10px" }}
              >
                <Grid item xs={12} gap={2}>
                  <InputLabel
                    className="text-font"
                    sx={{
                      fontSize: "16px",
                      color: "3B4A45",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    Query <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Write something..."
                    sx={{
                      background: "#F9FAFB",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#8155FF",
                        },
                      },
                    }}
                    {...register("query")}
                    error={Boolean(errors.query)}
                  />
                  <Box sx={styles.validationText}>
                    {errors.query && errors.query.message}
                  </Box>
                </Grid>
                <Grid item xs={12} gap={2} sx={{ mt: "30px" }}>
                  <Button
                    fullWidth
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#916BFE",
                      "&:hover": {
                        backgroundColor: "#7455D0",
                        color: "#FFFFFF",
                      },
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sx={{
                  px: { md: "107px", sm: "40px", xs: "10px" },
                  mt: "61px",
                  mb: "50px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: { lg: "0px", xs: "10px" },
                    paddingRight: "16px",
                  }}
                  container
                ></Grid>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sx={{
                    gap: { lg: "16px", xs: "4px" },
                    mt: { lg: "0px", xs: "20px" },
                  }}
                  container
                  alignItems="center"
                >
                  <Grid item>
                    <img src={EmailIcon} alt="" />
                  </Grid>
                  <Grid item>
                    <Typography
                      className="text-font"
                      sx={{
                        fontSize: "13px",
                        color: "#3B4A45",
                      }}
                    >
                      EMAIL
                    </Typography>
                    <Typography
                      className="text-font"
                      sx={{
                        whiteSpace: "nowrap",

                        fontSize: "13px",
                        color: "#916BFE",
                      }}
                    >
                      <a
                        href="mailto:info@membershipwallet.co.uk"
                        style={{ textDecoration: "none", color: "#8155FF" }}
                      >
                        info@membershipwallet.co.uk
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}>
            <Grid item xs={12} sx={{ mt: "100px" }}>
              <Typography
                variant="h6"
                className="text-font"
                sx={{
                  color: "#8155FF",
                  // fontSize: "60px",
                  fontSize: { xs: "40px", md: "60px" },
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "55px",
                  letterSpacing: "-1.2px",
                  textAlign: "center",
                  mb: 8,
                }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>

            {faqs.map((faq, i) => (
              <Accordion
                key={i}
                sx={{
                  marginTop: 2,
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                  borderRadius: "8px",
                  // border: "1px solid #8155FF",
                  border: expanded[i] ? "1px solid #8155FF" : "none",
                }}
                expanded={expanded[i]}
                onChange={(event, isExpanded) => {
                  setExpanded((prevExpanded) => {
                    const newExpanded = [...prevExpanded];
                    newExpanded[i] = isExpanded;
                    return newExpanded;
                  });
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#8155FF" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background: expanded[i] ? "#F1ECFF" : "#FFFFFF",
                    borderRadius: "8px",
                    padding: "5px 24px",
                    color: "#8155FF",
                  }}
                >
                  <Typography variant="h6">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    sx={{ p: "10px 20px", textAlign: "justify" }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </form>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          sx={{ background: "#8155FF", color: "white" }}
        >
          Your message have been sent successfully! We will be in contact with
          you soon.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;

const styles = {
  validationText: {
    color: "red",
    marginTop: "4px",
    fontSize: "14px",
  },
};
