// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    &-wrap {
      margin-top: 200px;
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
    }
  
    &-item {
      border: 3px solid #D8515D;
      border-radius: 50%;
      height: 600px;
      width: 600px;
      position: absolute;
      left: 50%;
      top: 5%;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation: zoom 2s linear 0.75s infinite;
  
      &--2 {
        animation-delay: 1.25s;
      }
  
      &--3 {
        animation-delay: 1.75s;
      }
    }
  }
  
  @keyframes zoom {
    0% {
      transform: translate(-50%, -50%) scale(0.2);
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/under-constraction/style.css"],"names":[],"mappings":"AAAA;IACI;MACE,iBAAiB;MACjB,kBAAkB;MAClB,SAAS;MACT,QAAQ;MACR,gCAAgC;IAClC;;IAEA;MACE,yBAAyB;MACzB,kBAAkB;MAClB,aAAa;MACb,YAAY;MACZ,kBAAkB;MAClB,SAAS;MACT,OAAO;MACP,gCAAgC;MAChC,UAAU;MACV,wCAAwC;;MAExC;QACE,sBAAsB;MACxB;;MAEA;QACE,sBAAsB;MACxB;IACF;EACF;;EAEA;IACE;MACE,2CAA2C;MAC3C,UAAU;IACZ;IACA;MACE,YAAY;IACd;IACA;MACE,yCAAyC;MACzC,UAAU;IACZ;EACF","sourcesContent":[".spinner {\n    &-wrap {\n      margin-top: 200px;\n      position: absolute;\n      left: 50%;\n      top: 30%;\n      transform: translate(-50%, -50%);\n    }\n  \n    &-item {\n      border: 3px solid #D8515D;\n      border-radius: 50%;\n      height: 600px;\n      width: 600px;\n      position: absolute;\n      left: 50%;\n      top: 5%;\n      transform: translate(-50%, -50%);\n      opacity: 0;\n      animation: zoom 2s linear 0.75s infinite;\n  \n      &--2 {\n        animation-delay: 1.25s;\n      }\n  \n      &--3 {\n        animation-delay: 1.75s;\n      }\n    }\n  }\n  \n  @keyframes zoom {\n    0% {\n      transform: translate(-50%, -50%) scale(0.2);\n      opacity: 0;\n    }\n    50% {\n      opacity: 0.9;\n    }\n    100% {\n      transform: translate(-50%, -50%) scale(1);\n      opacity: 0;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
