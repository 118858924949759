import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import authlogo from "../../../assets/auth/sign-in/Logo.svg";
import boxbg from "../../../assets/auth/sign-up/x.png";
import { Button } from "@mui/material";
import setting from "../../../assets/auth/icon/sett.svg";
import laptop from "../../../assets/auth/sign-in/coin.svg";
import lodo from "../../../assets/auth/sign-up/vdf.png";
import twiter from "../../../assets/auth/icon/twiter.svg";
import insta from "../../../assets/auth/icon/insta.svg";
import fb from "../../../assets/auth/icon/fb.svg";
import linkedin from "../../../assets/auth/icon/in.svg";
import youtube from "../../../assets/auth/icon/yout.svg";
import { useNavigate } from "react-router-dom";
import applogo from "../../../assets/logo.svg";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast, { Toaster } from "react-hot-toast";

const images = [fb, insta, linkedin, twiter, youtube];

const socialMediaLinks = [
  "https://www.facebook.com/Membershipswallet",
  "https://www.instagram.com/membershipwalletofficial_/",
  "https://www.linkedin.com/company/membership-wallet/",
  "https://x.com/membershipwalet",
  "https://www.youtube.com/@membershipwalletofficial",
];

const SignIn = ({ setUserData }) => {
  const companyShortCode = "MW";
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const navigationHandler = (path) => {
    navigate(path);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const ValidationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    password: yup.string().required("Password is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const onSubmit = async (formValues) => {
    formValues.companyShortCode = companyShortCode;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      const responseData = await response.json();
      if (responseData && responseData?.data && responseData?.data?.user) {
        const user = responseData.data.user;
        const token = responseData.data.token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        setUserData(responseData?.data.user);
        toast.success(responseData?.message ?? `Sign-in Successfully!`);
        navigate(`/status-page`);
      } else {
        throw new Error("User does not exist");
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg ?? "Something went wrong!");
    }
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
      navigate(-1);
    }
  }, [navigate, setUserData]);
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item md={5} xs={12}>
          <Box
            onClick={() => navigationHandler("/")}
            sx={{
              mt: "40px",
              display: { sm: "flex", xs: "none" },
              justifyContent: { lg: "left", xs: "center" },
              cursor: "pointer",
            }}
          >
            <img src={authlogo} alt="logo" />
          </Box>
          <Box
            sx={{
              mt: "48px",
              display: { sm: "none", xs: "flex" },
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <img src={applogo} alt="logo" />
          </Box>

          <Box
            sx={{
              mt: "80px",
              display: "flex",
              justifyContent: { lg: "left", xs: "center" },
            }}
          >
            <Typography
              className="text-font"
              sx={{
                color: "#000047",
                fontSize: { sm: "36px", xs: "26px" },
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "60px",
                letterSpacing: "-0.72px",
              }}
            >
              Sign In to your account
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ mt: "34px" }}>
              <Grid item xs={12}>
                <InputLabel
                  fullWidth
                  className="text-font"
                  sx={{
                    fontSize: "16px",
                    color: "3B4A45",
                    fontWeight: 500,
                    lineHeight: "24px",
                    mt: "20px",
                  }}
                >
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder="Enter email"
                  className="text-font"
                  sx={{
                    fontSize: "16px",
                    background: "#FFFFFF",
                    mt: "8px",
                    borderRadius: "8px",
                    border: "1px solid #EFF1F3",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#8155FF",
                      },
                    },
                    "&::placeholder": {
                      color: " var(--gray-scale-gray-400, #98A2B3)",
                    },
                  }}
                  {...register("email")}
                  error={Boolean(errors.email)}
                />
                <Box sx={{ color: "red", marginTop: "4px" }}>
                  {errors.email && errors.email.message}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className="text-font"
                  sx={{
                    fontSize: "16px",
                    color: "3B4A45",
                    fontWeight: 500,
                    lineHeight: "24px",
                    mt: "20px",
                  }}
                >
                  Password
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder="*********"
                  type={showPassword ? "text" : "password"}
                  className="text-font"
                  sx={{
                    fontSize: "16px",
                    border: "1px solid #EFF1F3",
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    mt: "8px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#8155FF",
                      },
                    },
                    "&::placeholder": {
                      color: " var(--gray-scale-gray-400, #98A2B3)",
                    },
                  }}
                  InputProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "752px",
                      gap: "20px",
                      borderRadius: "8px",
                      border: "1.5px solid  #E5E7EB",
                      background: " #FFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleShowPassword}
                          edge="end"
                          aria-label="toggle password visibility"
                          style={{ color: "#4E4B66" }}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("password")}
                  error={Boolean(errors.password)}
                />
                <Box sx={{ color: "red", marginTop: "4px" }}>
                  {errors.password && errors.password.message}
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Typography
                className="text-font"
                sx={{
                  color: "#000047",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "28px",
                  textAlign: "center",
                  mt: "36px",
                }}
              >
                Don’t have an account?
                <span
                  style={{ color: "#8155FF", cursor: "pointer" }}
                  onClick={() => navigationHandler("/sign-up")}
                >
                  {" "}
                  Sign Up
                </span>
              </Typography>
            </Box>

            <Typography
              sx={{
                textAlign: "center",
                paddingY: "10px",
                color: "red",
                fontSize: "16px",
                fontWeight: "600",
                mt: "30px",
              }}
            >
              {error}
            </Typography>

            <Button
              fullWidth
              sx={{
                color: "#FFF",
                backgroundColor: "#916BFE",
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: 600,
                mt: "5px",
                "&:hover": {
                  backgroundColor: "#7455D0",
                  color: "#FFFFFF",
                },
              }}
              type="submit"
            >
              Sign In
            </Button>
          </form>

          <Grid xs={12}>
            <Typography
              className="text-font"
              sx={{
                color: "#000047",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "28px",
                textAlign: { lg: "left", xs: "center" },
                mt: "90px",
                pb: 8,
              }}
            >
              Copyrights © {new Date().getFullYear()} All Rights Reserved by
              <span
                style={{ color: "#8155FF", cursor: "pointer" }}
                onClick={() => navigationHandler("/")}
              >
                {" "}
                Membership Wallet
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{ display: { lg: "inline", xs: "none" }, position: "relative" }}
        >
          <Box>
            <Box
              sx={{
                position: "absolute",
                top: "39px",
                left: "160px",
                mt: 2,
                zIndex: -1,
              }}
            >
              <img
                src={setting}
                alt="background"
                style={{ position: "absolute" }}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "540px",
                left: "740px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={boxbg}
                alt="background"
                style={{
                  position: "absolute",
                  zIndex: 0.1,
                  opacity: 0.99,
                }}
              />
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "940px",
                left: "530px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              {images.map((image, index) => (
                <Grid item xs={6} sm={4} md={1} key={index}>
                  <a
                    href={socialMediaLinks[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={image}
                      alt=""
                      className="grid-image"
                      style={{ width: "30px", height: "30px", margin: "8px" }}
                    />
                  </a>
                </Grid>
              ))}
            </Box>

            <div style={{ position: "relative" }}>
              <img
                src={laptop}
                alt="background"
                style={{
                  position: "absolute",
                  zIndex: 2,
                  width: "460px",
                  height: "663px",
                  flexShrink: 0,
                  top: "240px",
                  left: "210px",
                }}
              />
              <img
                src={lodo}
                alt="background"
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "72px",
                  right: "-40px",
                  width: "69px",
                  height: "69px",
                  flexShrink: 0,
                }}
              />
            </div>
            <div
              style={{
                background: "#8155FF",
                height: "982px",
                width: "536px",
                borderRadius: "40px",
                position: "absolute",
                zIndex: -1,
                left: "423px",
                top: "40px",
              }}
            >
              {" "}
            </div>
          </Box>
        </Grid>
      </Grid>
      <Toaster />
    </Container>
  );
};

export default SignIn;
