// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
img.map-img {
    max-width: 425px !important;
}


.overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F9FC; 
    opacity: 0.7; 
    border-radius: "55px";
    z-index: 2; 
  }
  

@media (max-width: 460px) {
    img.map-img{
      max-width: 320px !important;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/business/business.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;AAC/B;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,UAAU;EACZ;;;AAGF;IACI;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["\nimg.map-img {\n    max-width: 425px !important;\n}\n\n\n.overlay::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #F8F9FC; \n    opacity: 0.7; \n    border-radius: \"55px\";\n    z-index: 2; \n  }\n  \n\n@media (max-width: 460px) {\n    img.map-img{\n      max-width: 320px !important;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
