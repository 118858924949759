// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS */
.custom-button {
    display: flex;
    width: 144px;
    padding: 10px;
    justify-content: flex-end; /* Align text to the right within the button */
    align-items: center; /* Vertically center the text within the button */
    gap: 10px;
    border-radius: 30px;
    background-color: transparent;
    border: 1px solid #FFF;
    margin-top: 24px;
    text-transform: capitalize;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/offers/offers.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,yBAAyB,EAAE,8CAA8C;IACzE,mBAAmB,EAAE,iDAAiD;IACtE,SAAS;IACT,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;IACtB,gBAAgB;IAChB,0BAA0B;EAC5B","sourcesContent":["/* CSS */\n.custom-button {\n    display: flex;\n    width: 144px;\n    padding: 10px;\n    justify-content: flex-end; /* Align text to the right within the button */\n    align-items: center; /* Vertically center the text within the button */\n    gap: 10px;\n    border-radius: 30px;\n    background-color: transparent;\n    border: 1px solid #FFF;\n    margin-top: 24px;\n    text-transform: capitalize;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
