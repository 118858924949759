import React, { useState } from "react";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import DownIcon from "../../../../assets/contactus/arrow-down.svg";
import toast, { Toaster } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .nullable()
    .defined()
    .required("First name is required")
    .matches(
      /^(?!\s*$)[A-Za-z\s]*$/,
      "Numeric values, only spaces and special characters are not allowed"
    ),
  lastName: Yup.string()
    .trim()
    .nullable()
    .defined()
    .required("Last name is required")
    .matches(
      /^(?!\s*$)[A-Za-z\s]*$/,
      "Numeric values, only spaces and special characters are not allowed"
    ),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|[a-zA-Z]{2,})$/,
      "Invalid email address"
    ),
  contactNumber: Yup.string()
    .required("Phone Number is Required")
    .trim()
    .typeError("Must be a number")
    .min(10, "Invalid Phone Number")
    .matches(/^\+44\d{10}$/, "Invalid Phone Number"),
  dob: Yup.date()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long"
    ),
});

const PersonalSignUp = () => {
  const companyShortCode = "MW";
  const [status, setStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const navigationHandler = (path) => {
    navigate(path);
  };
  const vertical = "top";
  const horizontal = "center";

  const today = new Date();
  const dateString = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      companyName: "",
      crn: "",
      address: "",
      dob: "",
    },
    resolver: yupResolver(ValidationSchema),
  });

  const onSubmit = async (formValues) => {
    formValues.companyShortCode = companyShortCode;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      const responseData = await response.json();

      if (responseData?.data !== null) {
        toast.success(
          responseData?.message ?? `Sign Up Successfully!. Check Your Email.`
        );
        navigate(`/verification?userId=${responseData?.data?.userId}`);
      } else {
        toast.error(responseData?.message || responseData?.errors[0]);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <Box
        sx={{
          mt: "20px",
          display: "flex",
          justifyContent: { lg: "left", xs: "center" },
        }}
      >
        <Typography
          className="text-font"
          sx={{
            color: "#000047",
            fontSize: { sm: "36px", xs: "26px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "60px",
            letterSpacing: "-0.72px",
          }}
        >
          Sign up for a Personal Account
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ mt: "34px" }}>
          <Grid item xs={12} lg={6}>
            <InputLabel
              className="text-font"
              sx={{
                fontSize: "16px",
                color: "3B4A45",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              First Name <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter first name"
              className="text-font"
              sx={{
                fontSize: "16px",
                mt: "8px",
                border: "1px solid #EFF1F3",
                background: "#FFFFFF",
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#8155FF",
                  },
                },
                "&::placeholder": {
                  color: " var(--gray-scale-gray-400, #98A2B3)",
                },
              }}
              {...register("firstName")}
              error={Boolean(errors.firstName)}
            />
            <Box sx={styles.validationText}>
              {errors.firstName && errors.firstName.message}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel
              className="text-font"
              sx={{
                fontSize: "16px",
                color: "3B4A45",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Last Name <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter last name"
              className="text-font"
              sx={{
                fontSize: "16px",
                border: "1px solid #EFF1F3",
                background: "#FFFFFF",
                borderRadius: "8px",
                mt: "8px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#8155FF",
                  },
                },
                "&::placeholder": {
                  color: " var(--gray-scale-gray-400, #98A2B3)",
                },
              }}
              {...register("lastName")}
              error={Boolean(errors.lastName)}
            />
            <Box sx={styles.validationText}>
              {errors.lastName && errors.lastName.message}
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography>
              <InputLabel
                fullWidth
                className="text-font"
                sx={{
                  fontSize: "16px",
                  py: "12px",
                }}
              >
                Phone Number <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                className="text-font"
                sx={{
                  fontSize: "16px",
                  background: "#F9FAFB",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#8155FF",
                    },
                  },
                }}
                placeholder="+441234567890"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#4B5563" }}
                    >
                      UK{" "}
                      <img
                        src={DownIcon}
                        alt="My Icon"
                        style={{ height: "20px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                {...register("contactNumber")}
                error={Boolean(errors.contactNumber)}
              />
              <Box sx={styles.validationText}>
                {errors.contactNumber && errors.contactNumber.message}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputLabel
              className="text-font"
              sx={{
                fontSize: "16px",
                py: "8px",
                color: "3B4A45",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              DOB <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              type="date"
              fullWidth
              placeholder="Enter first name"
              className="text-font"
              sx={{
                fontSize: "16px",
                mt: "8px",
                border: "1px solid #EFF1F3",
                background: "#FFFFFF",
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#8155FF",
                  },
                },
                "&::placeholder": {
                  color: " var(--gray-scale-gray-400, #98A2B3)",
                },
              }}
              {...register("dob")}
              error={Boolean(errors.dob)}
              inputProps={{
                max: dateString,
              }}
            />
            <Box sx={styles.validationText}>
              {errors.dob && errors.dob.message}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              fullWidth
              className="text-font"
              sx={{
                fontSize: "16px",
                color: "3B4A45",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Email <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Enter email"
              className="text-font"
              sx={{
                fontSize: "16px",
                background: "#FFFFFF",
                mt: "8px",
                borderRadius: "8px",
                border: "1px solid #EFF1F3",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#8155FF",
                  },
                },
                "&::placeholder": {
                  color: " var(--gray-scale-gray-400, #98A2B3)",
                },
              }}
              {...register("email")}
              error={Boolean(errors.email)}
            />
            <Box sx={styles.validationText}>
              {errors.email && errors.email.message}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              className="text-font"
              sx={{
                fontSize: "16px",
                color: "3B4A45",
                fontWeight: 500,
                lineHeight: "24px",
                mt: "20px",
              }}
            >
              Password <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              placeholder="*********"
              type={showPassword ? "text" : "password"}
              className="text-font"
              sx={{
                fontSize: "16px",
                border: "1px solid #EFF1F3",
                background: "#FFFFFF",
                borderRadius: "8px",
                mt: "8px",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#8155FF",
                  },
                },
                "&::placeholder": {
                  color: " var(--gray-scale-gray-400, #98A2B3)",
                },
              }}
              InputProps={{
                style: {
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "752px",
                  gap: "20px",
                  borderRadius: "8px",
                  border: "1.5px solid  #E5E7EB",
                  background: " #FFF",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                      style={{ color: "#4E4B66" }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password")}
              error={Boolean(errors.password)}
            />
            <Box sx={styles.validationText}>
              {errors.password && errors.password.message}
            </Box>
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              className="text-font"
              sx={{
                color: "#000047",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "28px",
                textAlign: "center",
                mt: "16px",
              }}
            >
              Already have an account?
              <span
                style={{ color: "#8155FF", cursor: "pointer" }}
                onClick={() => navigationHandler("/sign-in")}
              >
                {" "}
                Sign In
              </span>
            </Typography>
          </Box>
        </Grid>

        <Button
          fullWidth
          sx={{
            color: "#FFF",
            backgroundColor: "#916BFE",
            textTransform: "capitalize",
            fontSize: "16px",
            fontWeight: 600,
            mt: "38px",
            "&:hover": {
              backgroundColor: "#7455D0",
              color: "#FFFFFF",
            },
          }}
          type="submit"
        >
          Sign Up
        </Button>
      </form>
      <Toaster />
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        {status ? (
          <Alert onClose={handleClose} severity="success">
            You have successfully registered. Please Check your Email!{" "}
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Email Already exists
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default PersonalSignUp;

const styles = {
  validationText: {
    color: "red",
    marginTop: "4px",
  },
};
