import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const StatusPage = ({ userData }) => {
  const lineStyle = {
    width: "191px",
    height: "3px",
    backgroundColor: "#DECDC6",
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    if (isEmpty(userData)) {
      window.history.back();
    }
  }, [userData]);
  return (
    <Box>
      <Box
        sx={{ background: "radial-gradient( #8155FF, #8155FF)" }}
        //   height={"100vh"}
      >
        <Box
          sx={{
            backgroundPosition: "right center",
            backgroundSize: 1000,
            pt: {
              sm: 25,
              xs: 20,
            },
            pb: {
              md: 10,
              xs: 3,
            },
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              maxWidth: {
                md: "90%",
                xs: "100%",
              },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              alignItems="center"
              direction="column"
              spacing={0}
              textAlign="center"
            >
              <Stack
                direction="column"
                alignItems="center"
                spacing={3}
                sx={{
                  pb: {
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  maxWidth={540}
                  color="common.white"
                  sx={{ lineHeight: "30px" }}
                >
                  We appreciate your interest and are pleased to inform you that
                  your query has been successfully submitted.
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                  }}
                  color="common.white"
                >
                  Your verification status is :{" "}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                    }}
                    color="common.white"
                    component="span"
                  >
                    {userData?.verificationStatus}
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle1"
                  maxWidth={540}
                  color="common.white"
                  sx={{ lineHeight: "30px" }}
                >
                  One of our sales agents will review your information and
                  contact you shortly to assist with your request.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    maxWidth={540}
                    color="common.white"
                    sx={{ lineHeight: "30px" }}
                  >
                    If you have any further questions or need immediate
                    assistance, please do not hesitate to reach out to us at{" "}
                    <Link to="/contact-us">
                      <Button
                        sx={{
                          borderRadius: "8px",
                          background: "#ffffff",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          padding: "8px 24px",
                          ml: 2,
                          color: "#8155FF",
                          textTransform: "capitalize",
                          textAlign: "center",
                          "&:hover": {
                            background: "#ffffff",
                          },
                        }}
                      >
                        Contact us
                      </Button>
                    </Link>
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusPage;
