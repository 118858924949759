import React, { useState } from "react";
import { Typography, Button, Box, SvgIcon, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import lunaPark from "../../../assets/offers/lunaPark.png";
import musicFestivalTrend from "../../../assets/offers/musicFestivalTrend.png";
import saleimgthree from "../../../assets/offers/persensale.svg";

const cards = [
  {
    img: lunaPark,
    discount: "60%",
    heading: "Event Tickets",
    rating: "4.9",
    ratingCount: "176 Ratings",
    title: "Admission to Luna Park, 15 June 2023 - 15 July 2023",
    price: "£15.00",
    priceDiscount: "£6.50",
  },
  {
    img: musicFestivalTrend,
    discount: "50%",
    heading: "Special Offer",
    rating: "4.9",
    ratingCount: "138 Ratings",
    title: "Admission to Music Festival, 01 July 2023 - 31 July 2023",
    price: "£20.00",
    priceDiscount: "£10.00",
  },
  {
    img: saleimgthree,
    discount: "20%",
    heading: "Fashion Sal",
    rating: "4.9",
    ratingCount: "196 Ratings",
    title: "Admission to Fashion Expo, 05 June 2023 - 05 August 2023",
    price: "£24.00",
    priceDiscount: "£18.00",
  },
];

const cardColors = {
  "60%": "rgba(242, 188, 2, 0.80)",
  "50%": "rgba(255, 95, 85, 0.80)",
  "20%": "rgba(4, 179, 124, 0.80)",
};

const TrendingCard = () => {
  const [hoveredStates, setHoveredStates] = useState(cards.map(() => false));

  // Helper function to truncate title
  function truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    } else {
      return title;
    }
  }

  // const buttonTexts = cards.map((card, index) =>
  //   hoveredStates[index] ? "View Details" : card.priceDiscount
  // );

  const handleMouseEnter = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = true;
    setHoveredStates(newHoveredStates);
  };

  const handleMouseLeave = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = false;
    setHoveredStates(newHoveredStates);
  };

  return (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid
          item
          lg={4}
          md={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
          key={index}
        >
          <Card
            style={{
              width: "100%",
              maxWidth: "448.714px",
              minHeight: "500px",
              borderRadius: "16px",
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
            }}
          >
            <CardMedia
              component="img"
              alt="Card Image"
              style={{
                width: "100%",
                borderRadius: "16px",
                maxWidth: "408.829px",
                minHeight: "265.074px",
                flexShrink: 0,
                position: "relative",
                padding: "1rem",
                overflow: "hidden",
              }}
              src={card.img}
            />
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  top: "-240px",
                  right: "-162px",
                  backgroundColor: cardColors[card.discount],
                  width: "99px",
                  height: "40px",
                  color: "white",
                  padding: "2px 8px",
                  borderRadius: "0px 8px 8px 8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {card.discount}
              </Typography>
            </Box>

            <CardContent>
              <Typography
                variant="body2"
                sx={{
                  color: "#796EFF",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.266px",
                  mt: 1.5,
                }}
                className="text-font"
              >
                {card.heading}
              </Typography>

              <Typography
                className="text-font"
                sx={{
                  color: "var(--gray-scale-gray-600, #475467)",
                  mt: 1,
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "28.252px",
                }}
              >
                {truncateTitle(card.title, 58)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#475467",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  mt: 1,
                }}
                className="text-font"
              >
                {card.rating}{" "}
                <SvgIcon sx={{ color: "#EFA029" }}>
                  <polygon points="12,2 15,8 22,8 16,13 19,20 12,15 5,20 8,13 2,8 9,8"></polygon>
                </SvgIcon>{" "}
                ({card.ratingCount})
              </Typography>
            </CardContent>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px 16px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  borderRadius: "6px",
                  background: "rgba(121, 110, 255, 0.10)",
                  width: "100%",
                  padding: "5px 0",
                  maxWidth: "240px",
                  color: "#796EFF",
                  "&:hover": {
                    background: "#796EFF",
                    color: "white",
                  },
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                {/* {buttonTexts[index]} */}
                {card.priceDiscount}
              </Button>
              <Typography
                sx={{
                  color: "var(--gray-scale-gray-500, #667085)",

                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textDecorationLine: "line-through",
                }}
                className="text-font"
              >
                {card.price}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default TrendingCard;
