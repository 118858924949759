import React, { useState } from "react";
import { Typography, Button, Box, SvgIcon, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import summerConscert from "../../../assets/offers/summerConscert.png";
import musicFestival from "../../../assets/offers/musicFestival.png";
import foodWine from "../../../assets/offers/foodWine.png";
import artExhibition from "../../../assets/offers/artExhibition.png";
import worldAdventure from "../../../assets/offers/worldAdventure.png";
import techSummit from "../../../assets/offers/techSummit.png";
import sport from "../../../assets/offers/sport.png";
import wellness from "../../../assets/offers/wellness.png";
// import cardEight from "../../../assets/offers/pcardeight.svg";

const cards = [
  {
    img: summerConscert,
    discount: "50%",
    heading: "Concert Nights",
    rating: "4.8",
    ratingCount: "245 Ratings",
    title: "Admission to Summer Concert Series, 10 July 2023 - 20 July 2023",
    price: "£36.00",
    priceDiscount: "£18.00",
  },

  {
    img: foodWine,
    discount: "20%",
    heading: "Gourmet Festival",
    rating: "4.7",
    ratingCount: "198 Ratings",
    title:
      "Admission to Food and Wine Festival, 01 August 2023 - 15 August 2023 ",
    price: "£20.00",
    priceDiscount: "£16.00",
  },
  {
    img: artExhibition,
    discount: "60%",
    heading: "Art Expo",
    rating: "4.6",
    ratingCount: "172 Ratings",
    title: "Admission to Modern Art Exhibition, 05 July 2023 - 30 July 2023",
    price: "£50.00",
    priceDiscount: "£30.00",
  },
  {
    img: worldAdventure,
    discount: "50%",
    heading: "Adventure Park",
    rating: "4.9",
    ratingCount: "301 Ratings",
    title: "Admission to Adventure World, 01 June 2023 - 01 July 2023",
    price: "£45.00",
    priceDiscount: "£22.50",
  },
  {
    img: techSummit,
    discount: "50%",
    heading: "Tech Convention",
    rating: "4.8",
    ratingCount: "154 Ratings",
    title: "Admission to Tech Innovators Summit, 20 July 2023 - 22 July 2023",
    price: "£60.00",
    priceDiscount: "£30.00",
  },
  {
    img: wellness,
    discount: "50%",
    heading: "Wellness Retreat",
    rating: "4.7",
    ratingCount: "210 Ratings",
    title:
      "Admission to Wellness and Spa Retreat, 10 August 2023 - 20 August 2023",
    price: "£100.00",
    priceDiscount: "£50.00",
  },
  {
    img: sport,
    discount: "50%",
    heading: "Sports Event",
    rating: "4.9",
    ratingCount: "320 Ratings",
    title:
      "Admission to National Sports Championship, 15 June 2023 - 25 June 2023",
    price: "£24.00",
    priceDiscount: "£12.00",
  },
  {
    img: musicFestival,
    discount: "50%",
    heading: "Theater Show",
    rating: "4.6",
    ratingCount: "180 Ratings",
    title:
      "Admission to Broadway Musical, 01 September 2023 - 30 September 2023",
    price: "£80.00",
    priceDiscount: "£40.00",
  },
];

const cardColors = {
  "60%": "rgba(242, 188, 2, 0.80)",
  "50%": "#3DA9F3",
  "20%": "rgba(4, 179, 124, 0.80)",
};

const PopularCards = () => {
  const [visibleCards, setVisibleCards] = useState(4); // New state variable
  const [hoveredStates, setHoveredStates] = useState(cards.map(() => false));

  // Helper function to truncate title
  function truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    } else {
      return title;
    }
  }

  // const buttonTexts = cards.map((card, index) =>
  //   hoveredStates[index] ? "View Details" : card.priceDiscount
  // );
  const handleViewMore = () => {
    setVisibleCards(cards.length); // Show all cards when "View More" is clicked
  };
  const handleViewLess = () => {
    setVisibleCards(4); // Show only 4 cards when "View Less" is clicked
  };

  const handleMouseEnter = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = true;
    setHoveredStates(newHoveredStates);
  };

  const handleMouseLeave = (index) => {
    const newHoveredStates = [...hoveredStates];
    newHoveredStates[index] = false;
    setHoveredStates(newHoveredStates);
  };

  return (
    <Grid container spacing={2}>
      {/* {cards.map((card, index) => ( */}
      {cards.slice(0, visibleCards).map((card, index) => (
        <Grid
          item
          lg={3}
          md={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
          key={index}
        >
          <Card
            style={{
              width: "100%",
              maxWidth: "448.714px",
              minHeight: "510px",
              borderRadius: "16px",
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
            }}
          >
            <CardMedia
              component="img"
              alt="Card Image"
              // height="265.074px"
              style={{
                width: "100%",
                borderRadius: "16px",
                maxWidth: "408.829px",
                minHeight: "265.074px",
                flexShrink: 0,
                position: "relative",
                padding: "1rem",
                overflow: "hidden",
              }}
              src={card.img}
            />

            <Box sx={{ position: "relative" }}>
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  top: "-250px",
                  right: "-162px",
                  backgroundColor: cardColors[card.discount],
                  width: "99px",
                  height: "40px",
                  color: "white",
                  padding: "2px 8px",
                  borderRadius: "0px 8px 8px 8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {card.discount}
              </Typography>
            </Box>

            <CardContent>
              <Typography
                variant="body2"
                sx={{
                  color: "#796EFF",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.266px",
                  mt: 1.5,
                }}
                className="text-font"
              >
                {card.heading}
              </Typography>

              <Typography
                className="text-font"
                sx={{
                  color: "var(--gray-scale-gray-600, #475467)",
                  mt: 1,
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "28.252px",
                }}
              >
                {truncateTitle(card.title, 50)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#475467",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  mt: 1,
                }}
                className="text-font"
              >
                {card.rating}{" "}
                <SvgIcon sx={{ color: "#EFA029" }}>
                  <polygon points="12,2 15,8 22,8 16,13 19,20 12,15 5,20 8,13 2,8 9,8"></polygon>
                </SvgIcon>{" "}
                ({card.ratingCount})
              </Typography>
            </CardContent>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px 16px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  borderRadius: "6px",
                  background: "rgba(121, 110, 255, 0.10)",
                  width: "100%",
                  padding: "5px 0",
                  maxWidth: "240px",
                  mr:1,
                  color: "#796EFF",
                  "&:hover": {
                    background: "#796EFF",
                    color: "white",
                  },
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                {/* {buttonTexts[index]} */}
                {card.priceDiscount}
              </Button>
              <Typography
                sx={{
                  color: "var(--gray-scale-gray-500, #667085)",

                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textDecorationLine: "line-through",
                }}
                className="text-font"
              >
                {card.price}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        {visibleCards < cards.length ? (
          <Button
            variant="contained"
            onClick={handleViewMore}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              justifyContent: "center",
              width: "180px",
              padding: "10px",
              borderRadius: "30px",
              bgcolor: "transparent",
              border: "1px solid #3DA9F3",
              mt: "24px",
              textTransform: "capitalize",
              color: "#3DA9F3",
              "&:hover": {
                bgcolor: "#796EFF",
                color: "#FFF",
              },
            }}
          >
            View More
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleViewLess}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              justifyContent: "center",
              width: "180px",
              padding: "10px",
              borderRadius: "30px",
              bgcolor: "transparent",
              border: "1px solid #3DA9F3",
              mt: "24px",
              textTransform: "capitalize",
              color: "#3DA9F3",
              "&:hover": {
                bgcolor: "#796EFF",
                color: "#FFF",
              },
            }}
          >
            View Less
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PopularCards;
