import { Button, ButtonGroup, Container } from "@mui/material";
import React, { useState } from "react";
import BusinessSignUp from "./forms/bussiness-sign-up";
import PersonalSignUp from "./forms/personal-sign-up";
import { Box, Grid, Typography } from "@mui/material";
import authlogo from "../../../assets/auth/sign-in/Logo.svg";
import boxbg from "../../../assets/auth/sign-up/x.png";
import setting from "../../../assets/auth/icon/sett.svg";
import laptop from "../../../assets/auth/sign-up/xxc.png";
import lodo from "../../../assets/auth/sign-up/vdf.png";
import twiter from "../../../assets/auth/icon/twiter.svg";
import insta from "../../../assets/auth/icon/insta.svg";
import fb from "../../../assets/auth/icon/fb.svg";
import linkedin from "../../../assets/auth/icon/in.svg";
import youtube from "../../../assets/auth/icon/yout.svg";
import { useNavigate } from "react-router-dom";
import applogo from "../../../assets/logo.svg";

const images = [fb, insta, linkedin, twiter, youtube];

const socialMediaLinks = [
  "https://www.facebook.com/Membershipswallet",
  "https://www.instagram.com/membershipwalletofficial_/",
  "https://www.linkedin.com/company/membership-wallet/",
  "https://x.com/membershipwalet",
  "https://www.youtube.com/@membershipwalletofficial",
];

const SignUp = () => {
  const [selectedAccountType, setSelectedAccountType] = useState("business");
  const handleCardSelect = (accountType) => {
    setSelectedAccountType(accountType);
  };

  const navigate = useNavigate();

  const navigationHandler = (path) => {
    navigate(path);
  };
  const vertical = "top";
  const horizontal = "center";

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        {/* First Grid: Text and Form */}
        <Grid item lg={5} xs={12}>
          <Box
            onClick={() => navigationHandler("/")}
            sx={{
              mt: "40px",
              display: { sm: "flex", xs: "none" },
              justifyContent: { lg: "left", xs: "center" },
              cursor: "pointer",
            }}
          >
            <img src={authlogo} alt="logo" />
          </Box>
          <Box
            onClick={() => navigationHandler("/")}
            sx={{
              mt: "48px",
              display: { sm: "none", xs: "flex" },
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <img src={applogo} alt="logo" />
          </Box>

          <Box sx={{ mt: "40px" }}>
            <ButtonGroup
              size="medium"
              color="primary"
              aria-label="Small button group"
              sx={{
                display: "flex",
                justifyContent: { lg: "start", xs: "center"},
                boxShadow: "none",
                borderRadius: "15px",
              }}
            >
              <Button
                variant={"outlined"}
                onClick={() => handleCardSelect("business")}
                sx={{
                  backgroundColor:
                    selectedAccountType === "business" ? "#7455D0" : "#ffff",
                  color:
                    selectedAccountType !== "business" ? "#7455D0" : "#ffff",

                  "&:hover": {
                    backgroundColor:
                      selectedAccountType === "business" ? "#7455D0" : "#ffff",
                    color:
                      selectedAccountType !== "business" ? "#7455D0" : "#ffff",
                  },
                }}
                disableFocusRipple
                disableElevation
                disableRipple
                disableTouchRipple
              >
                Delegate
              </Button>
              <Button
                variant={"outlined"}
                onClick={() => handleCardSelect("personal")}
                sx={{
                  backgroundColor:
                    selectedAccountType === "personal" ? "#7455D0" : "#ffff",
                  color:
                    selectedAccountType !== "personal" ? "#7455D0" : "#ffff",

                  "&:hover": {
                    backgroundColor:
                      selectedAccountType === "personal" ? "#7455D0" : "#ffff",
                    color:
                      selectedAccountType !== "personal" ? "#7455D0" : "#ffff",
                  },
                }}
                disableFocusRipple
                disableElevation
                disableRipple
                disableTouchRipple
              >
                Personal
              </Button>
            </ButtonGroup>
          </Box>

          {selectedAccountType === "business" && <BusinessSignUp />}
          {selectedAccountType === "personal" && <PersonalSignUp />}

          <Grid xs={12}>
            <Typography
              className="text-font"
              sx={{
                color: "#000047",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "28px",
                textAlign: { lg: "left", xs: "center" },
                mt: "90px",
                pb: 8,
              }}
            >
              Copyrights © {new Date().getFullYear()} All Rights Reserved by
              <span
                style={{ color: "#8155FF", cursor: "pointer" }}
                onClick={() => navigationHandler("/")}
              >
                {" "}
                Membership Wallet
              </span>
            </Typography>
          </Grid>
        </Grid>

        {/* Second Grid: Image */}
        <Grid
          item
          xs={12}
          lg={7}
          sx={{ display: { lg: "inline", xs: "none" }, position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "39px",
              left: "160px",
              mt: 2,
              zIndex: -1,
            }}
          >
            <img
              src={setting}
              alt="background"
              style={{ position: "absolute" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "540px",
              left: "740px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={boxbg}
              alt="background"
              style={{
                position: "absolute",
                zIndex: 0.1,
                opacity: 0.99,
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "940px",
              left: "530px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {images.map((image, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={1}
                key={index}
                // sx={{ display: "flex", mt: 2 }}
              >
                <a
                  href={socialMediaLinks[index]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="grid-image"
                    style={{ width: "30px", height: "30px", margin: "8px" }}
                  /> */}
                  <img
                    src={image}
                    alt=""
                    className="grid-image"
                    style={{ width: "30px", height: "30px", margin: "8px" }}
                  />
                </a>
              </Grid>
            ))}
          </Box>
          {/* <Box
            sx={{
              position: "absolute",
              top: "940px",
              left: "619px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={twiter}
              alt="twitter"
              style={{
                cursor: "pointer",
                position: "absolute",
                zIndex: 4,
                left: "0",
                top: "110%",
              }}
            />
            <img
              src={insta}
              alt="instagram"
              style={{
                cursor: "pointer",
                position: "absolute",
                zIndex: 4,
                left: "36px", // Adjust the left property for spacing
                top: "110%",
              }}
            />
            <img
              src={fb}
              alt="facebook"
              style={{
                cursor: "pointer",
                position: "absolute",
                zIndex: 4,
                left: "72px", // Adjust the left property for spacing
                top: "110%",
              }}
            />
            <img
              src={lin}
              alt="linkedin"
              style={{
                cursor: "pointer",
                position: "absolute",
                zIndex: 4,
                left: "108px", // Adjust the left property for spacing
                top: "110%",
              }}
            />
            <img
              src={yout}
              alt="youtube"
              style={{
                cursor: "pointer",
                position: "absolute",
                zIndex: 4,
                left: "144px", // Adjust the left property for spacing
                top: "110%",
              }}
            />
          </Box> */}

          <div style={{ position: "relative" }}>
            <img
              src={laptop}
              alt="background"
              style={{
                position: "absolute",
                zIndex: 2,
                width: "460px",
                height: "663px",
                flexShrink: 0,
                top: "240px",
                left: "210px",
              }}
            />
            <img
              src={lodo}
              alt="background"
              style={{
                position: "absolute",
                zIndex: 2,
                top: "72px",
                right: "-40px",
                width: "69px",
                height: "69px",
                flexShrink: 0,
              }}
            />
          </div>

          {/* </Box> */}
          <div
            style={{
              background: "#8155FF",
              height: "982px",
              width: "536px",
              borderRadius: "40px",
              position: "absolute",
              zIndex: -1,
              left: "423px",
              top: "40px",
            }}
          >
            {" "}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
