import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";
import Offers from "./pages/offers/offers";
import Business from "./pages/business/business";
import Careers from "./pages/careers/careers";
import ContactUs from "./pages/contact-us/contact-us";
import CookiePolicy from "./pages/cookie-policy/cookie-policy";
import PrivacyPolicy from "./pages/Privacy-policy/Privacy-policy";
import PartnerWithUs from "./pages/partner-with-us/partner-with-us";
import DelegateAccount from "./pages/delegate-account/delegate-account";
import AboutUs from "./pages/about-us/about-us";
import SignUp from "./pages/auth/sign-up/sign-up";
import Layout from "./lay-out/lay-out.jsx";
import SignIn from "./pages/auth/sign-in/sign-in";
import Error404 from "./pages/404/Error404";
import UnderConstraction from "./pages/under-constraction/under-constraction";
import TermsAndCondition from "./pages/terms-and-condition/terms-and-condition";
import GetVerified from "./pages/auth/get-verified/GetVerified.js";
import StatusPage from "./pages/status-page/status-page.js";
import toast from "react-hot-toast";

const SESSION_TIMEOUT = 600 * 1000;

function App() {
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  let timeoutId = useRef(null);

  function destroySession() {
    localStorage.removeItem("user");
    setUserData({});
    toast.error("Session expired. Please login again.");
    navigate("/sign-in");
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(destroySession, SESSION_TIMEOUT);
    return () => clearTimeout(timeoutId.current);
  }, [location]);

  return (
    <Routes>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn setUserData={setUserData} />} />
      <Route path="/under-constraction" element={<UnderConstraction />} />
      <Route path="*" element={<Error404 />} />

      <Route
        index
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/offers"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <Offers />
          </Layout>
        }
      />
      <Route
        path="/business"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <Business />
          </Layout>
        }
      />
      <Route
        path="/careers"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <Careers />
          </Layout>
        }
      />
      <Route
        path="/contact-us"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <ContactUs />
          </Layout>
        }
      />
      <Route
        path="/cookie-policy"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <CookiePolicy />
          </Layout>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <PrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path="/terms-and-condition"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            {/* <DataPolicy /> */}
            <TermsAndCondition />
          </Layout>
        }
      />
      <Route
        path="/partner-with-us"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <PartnerWithUs />
          </Layout>
        }
      />
      <Route
        path="/delegate-account"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <DelegateAccount />
          </Layout>
        }
      />
      <Route
        path="/about-us"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <AboutUs />
          </Layout>
        }
      />
      <Route
        path="/verification"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <GetVerified />
          </Layout>
        }
      />
      <Route
        path="/status-page"
        element={
          <Layout userId={userData?._id} setUserData={setUserData}>
            <StatusPage userData={userData} />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
