import React from "react";
import Footer from "./footer/footer";
import Header from "./header/header";

function Layout({ children, userId, setUserData }) {
  return (
    <div>
      <Header userId={userId} setUserData={setUserData} />
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
